export default {
  colors: {},
  sizes: {
    // bulma
    phone: 500,
    tablet: 750,
    desktop: 900,
    widescreen: 1050,
    fullhd: 1050,
  },
}
